<template >
  <!-- Mobile View -->
  <div class=" sm:hidden block">

    <div class="h-screen h-min-phone w-screen
    box-bg flex flex-col justify-between">
      <div class="box-bg-image">

      </div>

      <!--Main 0 -->
      <div v-bind:class="{'hidden': openTab !== 0, 'block': openTab === 0}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <!--Main Box  -->
        <div class="h-full mt-6 w-full  flex flex-col justify-center items-center">
          <h1 class="text-2xl px-10 mt-4 text-center font-bold text-gray-800">Which network do you want to create your token on?
          </h1>
          <section class="flex flex-col p-10 h-1/2 w-full">
            <button @click="changeNetworkType('ETH')" v-bind:class="networkType === 'ETH' ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] rounded-3xl flex justify-start items-center py-8 ">

              <div v-if="networkType === 'ETH' " class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB]  rounded-full flex justify-center items-center text-sm">
                <div class="w-3 h-3 bg-[#4FA9DB]  rounded-full"></div>
              </div>

              <div v-else class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center text-sm">
              </div>
              <img class="w-1/6 mr-10 aspect-square " src="./assets/ether-icon.svg" />
              <p v-bind:class="networkType === 'ETH' ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Ethereum
              </p>

            </button>

            <button @click="changeNetworkType('POLY')" v-bind:class="networkType === 'POLY' ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] rounded-3xl flex justify-start items-center py-8 my-6">

              <div v-if="networkType === 'POLY' " class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB]  rounded-full flex justify-center items-center text-sm">
                <div class="w-3 h-3 bg-[#4FA9DB]  rounded-full"></div>
              </div>

              <div v-else class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center text-sm">
              </div>
              <img class="w-1/6 mr-10 aspect-square " src="./assets/poly-icon.svg" />
              <p v-bind:class="networkType === 'POLY' ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Polygon
              </p>

            </button>


          </section>

        </div>
      </div>

      <!--Main 1 -->
      <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <!--Main Box  -->
        <div class="h-full mt-6 w-full  flex flex-col justify-center items-center">
          <h1 class="text-2xl px-10 mt-4 text-center font-bold text-gray-800">What type of token do you want to launch?
          </h1>
          <section class="flex flex-col p-10 h-1/2 w-full">
            <button @click="changeTokenType(null, 'ERC20')" v-bind:class="tokenType === 'ERC20' ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] rounded-3xl flex justify-start items-center py-8 ">

              <div v-if="tokenType === 'ERC20'" class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB]  rounded-full flex justify-center items-center text-sm">
                <div class="w-3 h-3 bg-[#4FA9DB]  rounded-full"></div>
              </div>

              <div v-else class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center text-sm">
              </div>
              <!-- <input @change="changeTokenType($event)" class="h-4 w-4 mx-10 accent-blue-600" type="radio"
                name="tokentype" value="ERC20"  /> -->
              <img class="w-1/6 mr-10 aspect-square " src="./assets/crypto_icon.svg" />
              <p v-bind:class="tokenType === 'ERC20' ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Currency
              </p>

            </button>

            <div v-bind:class="tokenType === 'ERC721' ? 'border-4':'border-2'"
              class=" border-2 border-gray-300  rounded-3xl flex justify-start items-center py-8 my-6 relative">
              <p class="text-md text-gray-400 w-full text-center absolute  bottom-0">Coming soon</p>
              <input @change="changeTokenType($event)" class="h-5 w-5 mx-10 accent-blue-600" type="radio"
                name="tokentype" value="ERC721" disabled />
              <img class="w-1/6 mr-10 aspect-square " src="./assets/unique_nft.svg" />
              <p v-bind:class="tokenType === 'ERC721' ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">NFT
              </p>
            </div>
            <!-- <div v-bind:class="tokenType === 'ERC1155' ? 'border-4':'border-2'"
        class=" border-2 border-[#4FA9DB] rounded-3xl xl:mx-8 flex flex-col justify-evenly items-center py-8 ">
        <input @change="changeTokenType($event)" class="xl:h-8 xl:w-8 md:h-4 md:w-4 accent-blue-600" type="radio"
          name="tokentype" value="ERC1155" />
        <img class="w-1/3 aspect-square " src="./assets/nft_collection.svg" />
        <p v-bind:class="tokenType === 'ERC1155' ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">NFT
          collection</p>
      </div> -->
          </section>

        </div>
      </div>


      <!--Main 2 -->
      <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <!--Main Box  -->
        <div class="h-full mt-6 w-full  flex flex-col justify-evenly  items-center">
          <h1 class="text-2xl px-10 mt-6 text-center font-bold text-gray-800">What is the name of your project?</h1>
          <div class="flex flex-col w-full justify-center items-center">
            <input @keyup="onTextChange($event,'name')" placeholder="Type the name here..." type="text" name="tokenname"
              id="tokenname" v-model="name"
              class="text-gray-700  font-normal centered w-3/4 text-lg rounded-full border-2 border-[#4FA9DB] p-4 px-8 mb-4" />
          </div>
          <button @click="toggleNext(3)"
            v-bind:class="name === null ? 'border-gray-300':'border-[#4FA9DB] hover:bg-blue-100'"
            class=" p-4 rounded-full flex justify-center items-center border-2 border-[#4FA9DB] ">
            <h2 v-bind:class="name === null ? 'text-gray-300':'text-blue-400 '" class="text-l font-medium ">
              Done</h2>
            <img v-bind:class="name !== null ? 'visible ':'invisible'" class=" ml-2 h-6 w-6" src="./assets/check.svg"
              alt="" />
          </button>

        </div>

      </div>
      <!--Main 3 -->
      <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <!--Main Box  -->
        <div class="h-full mt-6 w-full  flex flex-col justify-evenly  items-center">
          <h1 class="text-2xl px-10 mt-6 text-center font-bold text-gray-800">What is your token ticker?</h1>
          <h3 class="text-sm px-10  text-center font-light text-gray-800">Example: Bitcoin is BTC, Ethereum is ETH. A
            short form ticker that will be displayed on exchanges when your token is traded.</h3>
          <div class="flex flex-col w-full justify-center items-center">
            <input @keyup="onTextChange($event,'symbol')" placeholder="Type your answer here..." type="text"
              name="tokensymbol" id="tokensymbol" v-model="symbol"
              class="text-gray-700  font-normal centered w-3/4 text-lg rounded-full border-2 border-[#4FA9DB] p-4 px-8 mb-4" />
          </div>
          <button @click="toggleNext(4)"
            v-bind:class="symbol === null ? 'border-gray-300':'border-[#4FA9DB] hover:bg-blue-100'"
            class=" p-4 rounded-full flex justify-center items-center border-2 border-[#4FA9DB] ">
            <h2 v-bind:class="symbol === null ? 'text-gray-300':'text-blue-400 '" class="text-l  font-medium ">Done</h2>
            <img v-bind:class="symbol !== null ? 'visible ':'invisible'" class=" ml-2 h-6 w-6" src="./assets/check.svg"
              alt="" />
          </button>

        </div>

      </div>

      <!--Main 4 -->
      <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <!--Main Box  -->
        <div class="h-full mt-6 w-full  flex flex-col justify-evenly  items-center">
          <h1 class="text-2xl px-10 mt-6 text-center font-bold text-gray-800">How many tokens do you want to mint?</h1>
          <h3 class="text-sm px-10  text-center font-light text-gray-800">It can be anywhere from one to trillions.
            Consider your tokenomics before taking this step.</h3>
          <div class="flex flex-col w-full justify-center items-center">
            <input @keyup="onTextChange($event,'supply')" placeholder="Type your answer here..." type="number"
              name="initialsupply" id="initialsupply" v-model="tokenSupply"
              class="text-gray-700  font-normal centered w-3/4 text-lg rounded-full border-2 border-[#4FA9DB] p-4 px-8 mb-4" />
          </div>
          <button @click="toggleNext(5)"
            v-bind:class="supply === null ? 'border-gray-300':'border-[#4FA9DB] hover:bg-blue-100'"
            class=" p-4 rounded-full flex justify-center items-center border-2 border-[#4FA9DB] ">
            <h2 v-bind:class="supply === null ? 'text-gray-300':'text-blue-400 '" class="text-l  font-medium ">Done</h2>
            <img v-bind:class="supply !== null ? 'visible ':'invisible'" class=" ml-2 h-6 w-6" src="./assets/check.svg"
              alt="" />
          </button>

        </div>

      </div>

      <!--Main 5 -->
      <div v-bind:class="{'hidden': openTab !== 5, 'block': openTab === 5}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <!--Main Box  -->
        <div class="h-full mt-6 w-full  flex flex-col justify-center items-center">
          <h1 class="text-2xl px-10 mt-6 text-center font-bold text-gray-800">What extra features do you want in your
            token?
          </h1>
          <section class="flex flex-col p-10 h-1/2 w-full">
            <button @click="clickMintableToggle" v-bind:class="isMintable ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] rounded-3xl flex justify-start items-center py-8 ">


              <div v-if="isMintable" class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB]  rounded-full flex justify-center items-center text-sm">
                <div class="w-3 h-3 bg-[#4FA9DB]  rounded-full"></div>
              </div>

              <div v-else class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center text-sm">
              </div>

              <img class="w-1/6 mr-6 aspect-square " src="./assets/can_mint_icon.svg" />
              <p v-bind:class="isMintable  ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Can Mint
              </p>
            </button>
            <button @click="clickBurnableToggle" v-bind:class=" isBurnable ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] rounded-3xl flex justify-start items-center py-8 my-4 ">
              <div v-if="isBurnable" class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB]   rounded-full flex justify-center items-center text-sm">
                <div class="w-3 h-3 bg-[#4FA9DB]  rounded-full"></div>
              </div>
              <div v-else class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center text-sm">
              </div>
              <img class="w-1/6 mr-6 aspect-square " src="./assets/can_burn_icon.svg" />
              <p v-bind:class="isBurnable ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Can Burn
              </p>
            </button>
            <!-- <div v-bind:class="tokenType === 'ERC1155' ? 'border-4':'border-2'"
        class=" border-2 border-[#4FA9DB] rounded-3xl xl:mx-8 flex flex-col justify-evenly items-center py-8 ">
        <input @change="changeTokenType($event)" class="xl:h-8 xl:w-8 md:h-4 md:w-4 accent-blue-600" type="radio"
          name="tokentype" value="ERC1155" />
        <img class="w-1/3 aspect-square " src="./assets/nft_collection.svg" />
        <p v-bind:class="tokenType === 'ERC1155' ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">NFT
          collection</p>
      </div> -->
          </section>

        </div>

      </div>

      <!--Main 6 -->
      <div v-bind:class="{'hidden': openTab !== 6, 'block': openTab === 6}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <!--Main Box  -->
        <div class="h-full mt-6 w-full  flex flex-col justify-center items-center">
          <h1 class="text-2xl px-10 my-6 text-center font-bold text-gray-800">Summary
          </h1>
          <section class="h-2/3 w-5/6">
            <div style="border-width: 2px;"
              class="h-1/6  m-2 rounded-full  border-gray-200 flex items-center justify-between px-6">
              <h3 class="text-md text-center font-normal text-gray-600  ">Project Name
              </h3>
              <h3 class="text-md text-center font-normal text-blue-400  ">{{name}}
              </h3>
            </div>
            <div style="border-width: 2px;"
              class="h-1/6  m-2 rounded-full  border-gray-200 flex items-center justify-between px-6">
              <h3 class="text-md text-center font-normal text-gray-600 ">Symbol
              </h3>
              <h3 class="text-md text-center font-normal text-blue-400 ">{{symbol}}
              </h3>
            </div>
            <div style="border-width: 2px;"
              class="h-1/6  m-2 rounded-full  border-gray-200 flex items-center justify-between px-6">
              <h3 class="text-md text-center font-normal text-gray-600  ">Token Supply
              </h3>
              <h3 class="text-md text-center font-normal text-blue-400  ">{{tokenSupply}}
              </h3>
            </div>
            <div style="border-width: 2px;"
              class="h-1/6  m-2 rounded-full  border-gray-200 flex items-center justify-between px-6">
              <h3 class="text-md text-center font-normal text-gray-600  ">Token type
              </h3>
              <h3 class="text-md text-center font-normal text-blue-400  ">{{tokenType}}
              </h3>
            </div>

            <div class="h-1/6  m-2  flex items-center justify-between px-6">
              <h3 class="text-md text-center font-medium  text-gray-700  ">Fee
              </h3>
              <h3 class="text-md text-center font-normal  text-gray-600  ">$ 5.00
              </h3>
            </div>

          </section>
          <section class="h-1/6 flex items-center justify-center">
            <button @click="submit"
              class=" px-8 py-4 rounded-full flex justify-center items-center border-2 border-[#4FA9DB]  hover:bg-blue-100 ">
              <h2 class="text-l text-[#4FA9DB] font-medium ">Launch Project</h2>

            </button>
          </section>

        </div>
      </div>

      <!--Main 7 -->
      <div v-bind:class="{'hidden': openTab !== 7, 'block': openTab === 7}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <div class="h-full mt-6 w-full  flex flex-col justify-center items-center">
          <h1 class="flex text-3xl px-10 text-center font-bold text-gray-800 ">Deploying...
          </h1>
          <img class="flex h-32 w-32 my-4" src="./assets/loading-osis.gif" alt="">
          <div class="flex text-md px-6 text-center font-light  text-gray-800 ">Your token is now being built & deployed
            to the global blockchain network. This process may take up to 10 minutes, depending on how busy the network
            is… Please wait.
          </div>

        </div>

      </div>

      <!--Main 0 Erro  -->
      <div v-bind:class="{'hidden': openTab !== 9, 'block': openTab === 9}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <div class="h-full mt-6 w-full  flex flex-col justify-center items-center">
          <h1 class="flex text-3xl px-10 text-center font-bold text-gray-800 ">Error
          </h1>
          <img class="flex h-1/3 w-1/3" src="./assets/error-icon.svg" alt="">
          <div class="flex text-md px-6 text-center font-light  text-gray-800 ">{{errorMessage}}
          </div>

        </div>
      </div>


      <!--Main 9 -->
      <div v-bind:class="{'hidden': openTab !==8 , 'block': openTab === 8}"
        class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
        <div class="h-full mt-6 w-full  flex flex-col justify-center items-center">

            <div v-if="congratulationIcon" >
                <img  class="h-32 w-32 my-4" src="./assets/congratulation-icon.svg" alt="" />
              </div>
              <div v-else >
                <img  class="h-32 w-32 my-4" src="./assets/loading-osis.gif" alt="" />
              </div>
          <div>
            <h1 class="text-3xl px-10 text-center font-bold text-gray-800 ">Congratulations!</h1>
            <h1 class="text-xl px-10 my-2 text-center font-normal text-gray-800 ">Your token & smart contracts are now
              live!
            </h1>
            <!-- <h2 class="text-md px-10 mb-10 text-center font-light text-gray-800 ">Smart contract address: (smart
          contract address goes here)</h2> -->
          </div>

        </div>
      </div>


      <!--Buttton -->

      <div v-bind:class="{'items-center justify-center': openTab > 5, 'justify-between': openTab <6} "
        class="h-20 my-10 px-4 w-full flex">

        <button @click="backNavigation"
          v-bind:class="{'invisible': openTab > 6, 'visible block': openTab <7 || openTab === 9,  }"
          class="h-20 px-10  blur-box flex items-center  justify-center">
          <img class="mr-4 rotate-180" src="./assets/left-arrow.svg" alt="">
          <h2 class="text-l text-blue-400 font-medium ">Back</h2>
        </button>

        <button @click="nextpage" v-bind:class="{'hidden': openTab > 5, 'visible': openTab <6}"
          class="h-20 px-10 blur-box flex items-center justify-center">
          <h2 class="text-l text-blue-400 font-medium ">Next</h2>
          <img class="ml-4 " src="./assets/left-arrow.svg" alt="">
        </button>
      </div>




      <!--Footer -->

      <div class="h-20 bg-white justify-between flex items-center">

        <div class="flex ml-8 items-center">
          <img class="h-12 w-12 p-1 border-blue-300 border-4 rounded-full" src="./assets/logo_footer.svg" alt="">
          <h1 class="text-blue-300 text-xl ml-2 font-bold">OSIS Launchpad</h1>
        </div>

        <div class="mr-6 h-10 w-10">
          <img class="h-10 w-10" src="./assets/help-circle.svg" alt="">
        </div>
      </div>
    </div>
  </div>
  <!-- Large View-->

  <div class="sm:block hidden">
    <main
      class="h-screen w-screen bg-gradient-to-b from-blue-100 to-red-100 flex flex-col justify-between min-win-size">
      <section class="flex justify-between h-5/6 items-center w-full in-win-size">

        <button @click="backNavigation"
          v-bind:class="{'invisible block': openTab > 6, 'visible block': openTab <7 , 'hidden': openTab === 0}"
          class=" h-4/6  px-4 blur-box flex flex-col items-center justify-center blur-back">
          <img class="mb-4 rotate-180" src="./assets/left-arrow.svg" alt="" />
          <h2 class="text-l text-blue-400 font-medium ">back</h2>
        </button>
        <router-link router-link to="/" type="button" v-bind:class="{'block': openTab === 0,  'hidden': openTab > 0}"
          class=" h-4/6  px-4 blur-box flex flex-col items-center justify-center blur-back">
          <img class="mb-4 rotate-180" src="./assets/left-arrow.svg" alt="" />
          <h2 class="text-l text-blue-400 font-medium ">back</h2>
        </router-link>


<!--Page -0 -->
    <div v-bind:class="{'hidden': openTab !== 0, 'block': openTab === 0}"
          class="h-5/6 w-full w-3/4 blur-box flex flex-col min-size">
          <h1 class="text-3xl px-10 text-center font-bold text-gray-800 my-20">Which network do you want to create your token on?
          </h1>
          <section class="flex justify-evenly p-10 h-3/5 w-full">
            <button @click="changeNetworkType('ETH')" v-bind:class="networkType === 'ETH' ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] rounded-3xl  w-56   flex flex-col justify-evenly items-center py-8 ">

              <div v-if="networkType === 'ETH' " class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB]  rounded-full flex justify-center items-center text-sm">
                <div class="w-3 h-3 bg-[#4FA9DB]  rounded-full"></div>
              </div>

              <div v-else class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center text-sm">
              </div>
              <img class="h-32 my-4 aspect-square " src="./assets/ether-icon.svg" />
              <p v-bind:class="networkType === 'ETH' ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Ethereum
              </p>

            </button>

            <button @click="changeNetworkType('POLY')" v-bind:class="networkType === 'POLY' ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] rounded-3xl  w-56   flex flex-col justify-evenly items-center py-8 ">

              <div v-if="networkType === 'POLY' " class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB]  rounded-full flex justify-center items-center text-sm">
                <div class="w-3 h-3 bg-[#4FA9DB]  rounded-full"></div>
              </div>

              <div v-else class=" mx-10 w-5 h-5 border-2 border-[#4FA9DB] rounded-full flex justify-center items-center text-sm">
              </div>
              <img class="h-32 my-4 aspect-square " src="./assets/poly-icon.svg" />
              <p v-bind:class="networkType === 'POLY' ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Polygon
              </p>

            </button>


          </section>
        </div>





        <!--Page -1 -->
        <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}"
          class="h-5/6 w-full w-3/4 blur-box flex flex-col min-size">
          <h1 class="text-3xl px-10 text-center font-bold text-gray-800 my-20">What type of token do you
            want to
            launch?
          </h1>
          <section class="flex  justify-evenly h-1/2">
            <div v-bind:class="tokenType === 'ERC20' ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] w-56  rounded-3xl  flex flex-col justify-evenly items-center py-8 ">
              <input @change="changeTokenType($event)" class="h-6 w-6 accent-blue-600" type="radio"
                name="tokentype" value="ERC20" />
              <img class="w-1/3 aspect-square " src="./assets/crypto_icon.svg" />
              <p v-bind:class="tokenType === 'ERC20' ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Currency
              </p>
            </div>
            <div v-bind:class="tokenType === 'ERC721' ? 'border-4':'border-2'"
              class=" border-2 border-gray-300  rounded-3xl  w-56 flex flex-col justify-evenly items-center py-8  relative">
              <p class="text-lg text-gray-400 absolute top-4">Coming soon</p>
              <input @change="changeTokenType($event)" class="h-6 w-6 accent-blue-600" type="radio"
                name="tokentype" value="ERC721" disabled />
              <img class="w-1/3 aspect-square " src="./assets/unique_nft.svg" />
              <p v-bind:class="tokenType === 'ERC721' ? 'font-bold':'font-light'" class="text-lg text-gray-300">A
                unique
                NFT</p>
            </div>
            <div v-bind:class="tokenType === 'ERC1155' ? 'border-4':'border-2'"
              class=" border-2 border-gray-300  rounded-3xl  w-56 flex flex-col justify-evenly items-center py-8  relative">
              <p class="text-lg text-gray-400 absolute top-4">Coming soon</p>
              <input @change="changeTokenType($event)" class="h-6 w-6 accent-blue-600" type="radio"
                name="tokentype" value="ERC1155" disabled />
              <img class="w-1/3 aspect-square " src="./assets/nft_collection.svg" />
              <p v-bind:class="tokenType === 'ERC1155' ? 'font-bold':'font-light'" class="text-lg text-gray-300">NFT
                collection</p>
            </div>
          </section>
          <section class="flex justify-evenly  h-1/6">
            <div class=" w-56 flex flex-col items-center py-4   ">
              <img class="h-6 w-6 showinfo" src="./assets/information-icon.svg">
              <p class="bg-white text-center text-gray-800 p-2 m-2 rounded-md hide">A token or currency, similar to
                Bitcoin, that can represent shares of your business. Currencies are frequently used to sell a part of
                your
                business to investors & raise funding.</p>
            </div>
            <div class="   w-56 flex flex-col items-center py-4   ">
              <img class="h-6 w-6 showinfo" src="./assets/information-icon.svg">
              <p class="bg-white text-center text-gray-800 p-2 m-2 rounded-md hide">A single Non-Fungible Token - an
                item
                on the blockchain which can represent any physical or digital asset.</p>
            </div>
            <div class="  w-56  flex flex-col items-center py-4   ">
              <img class="h-6 w-6 showinfo" src="./assets/information-icon.svg">
              <p class="bg-white text-center text-gray-800 p-2 m-2 rounded-md hide">More than 1 NFT collection - up to
                tens of thousands of units. Big projects like Bored Ape Yacht Club create NFT Collections in order to
                tokenize a special membership pass. Your imagination is your only limit - NFTs can represent any
                physical
                or digital asset.</p>
            </div>
          </section>
        </div>

        <!--Page -2 -->
        <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}"
          class="h-5/6 w-3/4 blur-box flex flex-col min-size justify-evenly items-center">
          <h1 class="text-3xl px-10 text-center font-bold text-gray-800 my-10 min ">What is the name of
            your project?</h1>
          <div class="flex flex-col w-full justify-center items-center">
            <input @keyup="onTextChange($event,'name')" placeholder="Type the name of your project here..." type="text"
              name="tokenname" id="tokenname" v-model="name"
              class="text-gray-700  font-normal centered w-3/4 text-lg rounded-full border-2 border-[#4FA9DB] p-4 px-8 mb-4" />
            <div class="flex w-full justify-between ">
              <div></div>
              <div class="flex flex-col items-center w-1/4 justify-between">
                <div class=" flex flex-col items-center  mx-4">
                  <img class="h-6 w-6 showinfo" src="./assets/information-icon.svg">
                  <p class="bg-white text-center text-gray-800 p-2 m-2 rounded-md hide w-4/5">A token or currency,
                    similar
                    to
                    Bitcoin, that can represent shares of your business. Currencies are frequently used to sell a part
                    of
                    your
                    business to investors & raise funding.</p>
                </div>
              </div>

            </div>
          </div>
          <div class="flex w-3/4 justify-end  ">
            <button @click="toggleNext(3)"
              v-bind:class="name === null ? 'border-gray-300':'border-[#4FA9DB] hover:bg-blue-100'"
              class=" p-4 rounded-full flex justify-center items-center border-2 border-[#4FA9DB] ">
              <h2 v-bind:class="name === null ? 'text-gray-300':'text-blue-400 '"
                class="text-l text-blue-400 font-medium ">Done</h2>
              <img v-bind:class="name !== null ? 'visible ':'invisible'" class=" ml-2 h-6 w-6" src="./assets/check.svg"
                alt="" />
            </button>
          </div>

        </div>



        <!--Page -3 -->
        <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}"
          class="h-5/6 w-3/4 blur-box flex flex-col min-size justify-evenly items-center">
          <div>
            <h1 class="text-3xl px-10 text-center font-bold text-gray-800 my-10  ">What is your token
              ticker?</h1>
            <h1 class="text-xl text-center font-normal text-gray-700 my-10  ">Example: Bitcoin is
              BTC, Ethereum is ETH. A short form ticker that will be displayed on exchanges when your token is traded.
            </h1>
            <h3 class="text-md text-center font-normal text-gray-700 ">Up to 8 characters allowed.
            </h3>
          </div>

          <div class="flex flex-col w-full justify-center items-center">
            <input @keyup="onTextChange($event,'symbol')" placeholder="Type your answer here..." type="text"
              name="tokensymbol" id="tokensymbol" v-model="symbol"
              class="text-gray-700  font-normal centered w-3/4 text-lg rounded-full border-2 border-[#4FA9DB] p-4 px-8 mb-4" />
            <div class="flex w-full justify-between ">
              <div>
              </div>
              <div class="flex flex-col items-center w-1/4 justify-between">
                <div class=" flex flex-col items-center  mx-4">
                  <img class="xl:h-6 xl:w-6 md:h-4 md:w-4 showinfo" src="./assets/information-icon.svg">
                  <p class="bg-white text-center text-gray-800 p-2 m-2 rounded-md hide w-4/5">Do a little research on
                    CoinMarketCap.com to make sure your name isn’t already taken. This cannot be changed after it is
                    launched.</p>
                </div>
              </div>

            </div>
          </div>
          <div class="flex w-3/4 justify-end  ">
            <button @click="toggleNext(4)"
              v-bind:class="symbol === null ? 'border-gray-300':'border-[#4FA9DB] hover:bg-blue-100'"
              class=" p-4 rounded-full flex justify-center items-center border-2 border-[#4FA9DB] ">
              <h2 v-bind:class="symbol === null ? 'text-gray-300':'text-blue-400 '"
                class="text-l text-blue-400 font-medium ">Done</h2>
              <img v-bind:class="symbol !== null ? 'visible ':'invisible'" class=" ml-2 h-6 w-6"
                src="./assets/check.svg" alt="" />
            </button>
          </div>

        </div>

        <!--Page -4 -->
        <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}"
          class="h-5/6 w-3/4 blur-box flex flex-col min-size justify-evenly items-center">
          <div>
            <h1 class="text-3xl px-10 text-center font-bold text-gray-800 my-10 min ">How many tokens do
              you want to mint?</h1>
            <h1 class="text-xl text-center font-normal text-gray-700 my-10 min ">It can be anywhere from
              one to trillions.
              Consider your tokenomics before taking this step.
            </h1>

          </div>

          <div class="flex flex-col w-full justify-center items-center">
            <input @keyup="onTextChange($event,'supply')" placeholder="Type your answer here..." type="number"
              name="initialsupply" id="initialsupply" v-model="tokenSupply"
              class="text-gray-700  font-normal centered w-3/4 text-lg rounded-full border-2 border-[#4FA9DB] p-4 px-8 mb-4" />
            <div class="flex w-full justify-between ">
              <div>
              </div>
              <div class="flex flex-col items-center w-1/4 justify-between">
                <div class=" flex flex-col items-center  mx-4">
                  <img class="xl:h-6 xl:w-6 md:h-4 md:w-4 showinfo" src="./assets/information-icon.svg">
                  <p class="bg-white text-center text-gray-800 p-2 m-2 rounded-md hide w-4/5">If you choose Mintable &
                    Burnable in the next step, you can always adjust your total token supply in the future..</p>
                </div>
              </div>

            </div>
          </div>
          <div class="flex w-3/4 justify-end  ">
            <button @click="toggleNext(5)"
              v-bind:class="tokenSupply === null ? 'border-gray-300':'border-[#4FA9DB] hover:bg-blue-100'"
              class=" p-4 rounded-full flex justify-center items-center border-2 border-[#4FA9DB] ">
              <h2 v-bind:class="tokenSupply === null ? 'text-gray-300':'text-blue-400 '"
                class="text-l text-blue-400 font-medium ">Done</h2>
              <img v-bind:class="tokenSupply !== null ? 'visible ':'invisible'" class=" ml-2 h-6 w-6"
                src="./assets/check.svg" alt="" />
            </button>
          </div>

        </div>


        <!--Page -5 -->
        <div v-bind:class="{'hidden': openTab !== 5, 'block': openTab === 5}"
          class="h-5/6 w-full w-3/4 blur-box flex flex-col min-size">
          <h1 class="text-3xl px-10 text-center font-bold text-gray-800 mt-20 ">What extra features do
            you want in your token?
          </h1>
          <h3 class="text-md text-center font-normal text-gray-700  my-10 ">You may choose more than one.
          </h3>
         <section class="flex  justify-evenly h-1/2">
            <div v-bind:class="isMintable ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] rounded-3xl w-56 flex flex-col justify-evenly items-center py-8 ">
              <input @change="checkMintableToggle($event)" class="h-6 w-6 accent-blue-600" type="checkbox"
                name="burnableToggle" />
              <img class="w-1/3 aspect-square " src="./assets/can_mint_icon.svg" />
              <p v-bind:class="isMintable ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Can Mint
              </p>
            </div>
            <div v-bind:class="isBurnable ? 'border-4':'border-2'"
              class=" border-2 border-[#4FA9DB] rounded-3xl w-56 flex flex-col justify-evenly items-center py-8 ">
              <input @change="checkBurnableToggle($event)" class="h-6 w-6 accent-blue-600" type="checkbox"
                name="burnableToggle" />
              <img class="w-1/3 aspect-square " src="./assets/can_burn_icon.svg" />
              <p v-bind:class="isBurnable ? 'font-bold':'font-light'" class="text-lg text-[#4FA9DB]">Can Burn</p>
            </div>
            <div v-bind:class="isvoteable ? 'border-4':'border-2'"
              class=" border-2 border-gray-300  rounded-3xl w-56 flex flex-col justify-evenly items-center py-8  relative ">
              <p class="text-lg text-gray-400 absolute top-4">Coming soon</p>
              <input @change="checkCanVoteToggle($event)" class="h-6 w-6 accent-blue-300" type="checkbox"
                name="votableToggle" disabled />
              <img class="w-1/3 aspect-square " src="./assets/can_vote.svg" />
              <p v-bind:class="isvoteable ? 'font-bold':'font-light'" class="text-lg text-gray-300">Can Vote</p>
            </div>
          </section>
          <section class="flex justify-evenly h-1/6">
            <div class="  w-56 flex flex-col items-center py-4   ">
              <img class=" h-6 w-6 md:w-4 showinfo" src="./assets/information-icon.svg">
              <p class="bg-white text-center text-gray-800 p-2 m-2 rounded-md hide">This feature allows you to add more
                tokens to the total supply after launching. This is good for projects that want to be flexible with
                their
                tokenomics.</p>
            </div>
            <div class=" w-56 flex flex-col items-center py-4   ">
              <img class="h-6 w-6 showinfo" src="./assets/information-icon.svg">
              <p class="bg-white text-center text-gray-800 p-2 m-2 rounded-md hide">This feature allows you to remove
                tokens from the total supply after launching. This is good for projects that want to be flexible with
                their tokenomics.</p>
            </div>
            <div class="w-56 flex flex-col items-center py-4   ">
              <img class="h-6 w-6 showinfo" src="./assets/information-icon.svg">
              <p class="bg-white text-center text-gray-800 p-2 m-2 rounded-md hide">This feature allows holders to vote
                on
                things you permit them to. This is a useful feature for Decentralized Autonomous Organizations or
                projects
                that want to have a democratic system.</p>
            </div>
          </section>
        </div>

        <!--Page -6 -->
        <div v-bind:class="{'hidden': openTab !== 6, 'block': openTab === 6}"
          class="h-5/6 w-full w-3/4 blur-box flex flex-col min-size items-center">
          <h1 class="text-3xl px-10 text-center font-bold text-gray-800 my-10 ">Summary
          </h1>
          <section class="h-2/3 xl:w-1/3 w-1/2">
            <div style="border-width: 2px;"
              class="h-1/6  m-2 rounded-full  border-gray-200 flex items-center justify-between px-6">
              <h3 class="text-md text-center font-normal text-gray-600  ">Project Name
              </h3>
              <h3 class="text-md text-center font-normal text-blue-400  ">{{name}}
              </h3>
            </div>
            <div style="border-width: 2px;"
              class="h-1/6  m-2 rounded-full  border-gray-200 flex items-center justify-between px-6">
              <h3 class="text-md text-center font-normal text-gray-600 ">Symbol
              </h3>
              <h3 class="text-md text-center font-normal text-blue-400 ">{{symbol}}
              </h3>
            </div>
            <div style="border-width: 2px;"
              class="h-1/6  m-2 rounded-full  border-gray-200 flex items-center justify-between px-6">
              <h3 class="text-md text-center font-normal text-gray-600  ">Token Supply
              </h3>
              <h3 class="text-md text-center font-normal text-blue-400  ">{{tokenSupply}}
              </h3>
            </div>
            <div style="border-width: 2px;"
              class="h-1/6  m-2 rounded-full  border-gray-200 flex items-center justify-between px-6">
              <h3 class="text-md text-center font-normal text-gray-600  ">Token type
              </h3>
              <h3 class="text-md text-center font-normal text-blue-400  ">{{tokenType}}
              </h3>
            </div>

            <div class="h-1/6  m-2  flex items-center justify-between px-6">
              <h3 class="text-md text-center font-medium  text-gray-700  ">Fee
              </h3>
              <h3 class="text-md text-center font-normal  text-gray-600  ">$ 5.00
              </h3>
            </div>

          </section>
          <section class="h-1/6 flex items-center justify-center">
            <button @click="submit"
              class=" px-8 py-4 rounded-full flex justify-center items-center border-2 border-[#4FA9DB]  hover:bg-blue-100 ">
              <h2 class="text-l text-[#4FA9DB] font-medium ">Launch Project</h2>

            </button>
          </section>
        </div>

        <!--Page -7 -->
        <div v-bind:class="{'hidden': openTab !== 7, 'block': openTab === 7}"
          class="h-5/6  w-3/4 blur-box flex flex-col min-size items-center justify-evenly min-size">
          <h1 class="flex text-3xl px-10 text-center font-bold text-gray-800 ">Deploying...
          </h1>
          <img class="flex h-32 w-32 aspect-square" src="./assets/loading-osis.gif" alt="">
          <div class="flex text-md px-10 w-3/4 text-center font-light  text-gray-800 ">Your token is now being built &
            deployed to the global blockchain network. This process may take up to 10 minutes, depending on how busy the
            network is… Please wait.
          </div>
        </div>
        <!--Page -8 -->
        <div v-bind:class="{'hidden': openTab !== 8, 'block': openTab === 8}"
          class="h-5/6  w-3/4 blur-box flex flex-col min-size items-center justify-evenly min-size">

          <div v-if="congratulationIcon" >
                <img  class="h-32 w-32 my-4" src="./assets/congratulation-icon.svg" alt="" />
              </div>
              <div v-else >
                <img  class="h-32 w-32 my-4" src="./assets/loading-osis.gif"  alt="" />
              </div>
          <div>
            <h1 class="text-4xl px-10 text-center font-bold text-gray-800 ">Congratulations!</h1>
            <h1 class="text-2xl py-4 text-center font-bold text-gray-800 ">Your token & smart contracts are now live!
            </h1>
            <h2 class="text-md px-10 text-center font-normal text-gray-800 ">Smart contract address: (smart contract
              address goes here)</h2>
          </div>


        </div>



        <button @click="nextpage" v-bind:class="{'invisible': openTab > 5, 'visible': openTab <6}"
          class="h-4/6 px-4 blur-box flex flex-col items-center justify-center blur-next">
          <img class="mb-4" src="./assets/left-arrow.svg" alt="" />
          <h2 class="text-l text-blue-400 font-medium ">Start</h2>
        </button>


      </section>
      <footer class="h-1/5  flex-col">
        <div class="h-1/2">
        </div>
        <div class="h-1/2 bg-white flex justify-between items-center">
          <div class="flex ml-8 items-center">
            <img class="h-12 w-12 p-1 border-blue-300 border-4 rounded-full" src="./assets/logo_footer.svg" alt="">
            <h1 class="text-blue-300 text-xl ml-2 font-bold">OSIS Launchpad</h1>
          </div>

          <div class="mr-6 h-10 w-10">
            <img class="h-10 w-10" src="./assets/help-circle.svg" alt="">
          </div>
        </div>
      </footer>

    </main>

  </div>

</template>

<script>
import { ethers } from 'ethers';
import {
  ERC20,
  ERC721,
  ERC1155,
  ERC20GoerliAddress,
  ERC20MumbaiAddress,
  ERC20EthMainnetAddress,
  ERC721Address,
  ERC1155Address,
  ERC20PolygonAddress
} from './utils/constants';
import { erc20FactoryABI } from './abis/erc20factory';
import { erc721FactoryABI } from './abis/erc721factory';
import { erc1155FactoryABI } from './abis/erc1155factory';
import axios from 'axios';

export default {
  name: 'CreateToken',
  data() {
    return {
      congratulationIcon: false,
      accountBalance: "",
      isLoading: false,
      hasError: false,
      hasSuccess: false,
      errorMessage: "",
      successMessage: "",
      name: null,
      uri: "",
      symbol: null,
      tokenSupply: null,
      isBurnable: false,
      isMintable: false,
      isvoteable: false,
      tokenType: null,
      nameList: "",
      idList: "",
      erc1155NameList: [],
      erc1155IdList: [],
      erc20: ERC20,
      erc721: ERC721,
      erc1155: ERC1155,
      openTab: 0,
      networkType: null,
      circleTabs: [
        false,
        false,
        false,
        false,
        false,
        false
      ],
      lineTabs: [
        false,
        false,
        false,
        false,
        false,
        false
      ],
      connectionResponse: null
    }
  },
  async mounted() {
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    const balance = await signer.getBalance();
    this.accountBalance = ethers.utils.formatEther(balance);

  },
  methods: {
    testNetworkconnection: function () {

      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
      };

      axios.get("http://localhost:3080/verify", config).then(response => {
        this.connectionResponse = response.data;
      });
    },
    sendPostRequest: function () {
      const headers = {};
      const params = {};
      axios.post('', params, headers)
        .then(function (response) {
          console.log(response.data);
        });
    },
    sendGetRequest: function () {
      const headers = {};
      const params = {};
      axios.get('', params, headers)
        .then(function (response) {
          console.log(response.data);
        });
    },
    toggleNext: function (tab) {
      switch (tab) {

        case 1: {
          if (this.networkType != null) {
            this.toggleTabs(tab)
          }
        }
          break;
        case 2: {
          if (this.tokenType != null) {
            this.toggleTabs(tab)
          }
        }
          break;
        case 3: {
          if (this.name != null) {
            this.toggleTabs(tab)
          }
        }
          break;
        case 4: {
          if (this.name != null && this.symbol != null) {
            this.toggleTabs(tab)
            this.circleTabs[3] = true;
            this.lineTabs[3] = true;
          }
        }
          break;
        case 5: {
          if (this.name != null && this.symbol != null && this.tokenSupply != null) {
            this.circleTabs[4] = true;
            this.lineTabs[4] = true;
            this.toggleTabs(tab)
          }
        }
          break;
        case 6: {
          if (this.name != null && this.symbol != null && this.tokenSupply != null) {
            this.toggleTabs(tab)
            this.circleTabs[5] = true;
          }
        }
          break;
      }
    },
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber
    },
    isEmpty(str) {
      return (!str || str.length === 0 || str.trim() === "");
    },
    async createToken() {
      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const networkName = (await provider.getNetwork()).name


      if (this.tokenType == ERC20) {
        console.log(`network.name = ${networkName}`)
        let factoryAddress = ""
        switch (provider.network.name) {
          case "goerli": {
            factoryAddress = ERC20GoerliAddress
            break;
          }
          case "mainnet": {
            factoryAddress = ERC20EthMainnetAddress
            break;
          }
          case "matic": {
            factoryAddress = ERC20PolygonAddress
            break;
          }
          case "maticmum": {
            factoryAddress = ERC20MumbaiAddress
            break;
          }
          default: {
            factoryAddress = ERC20GoerliAddress
            break;
          }
        }
        const progenitorContract = new ethers.Contract(factoryAddress, erc20FactoryABI, signer);
        await progenitorContract.create(this.name, this.symbol, this.isBurnable, this.isMintable, this.tokenSupply);


        progenitorContract.on("Created", (name_, symbol_, type_, _tokenAddress) => {
          console.log(name_, symbol_, type_, _tokenAddress);
          this.addTokenToWallet(_tokenAddress, symbol_)
        });


      }

      if (this.tokenType == ERC721) {
        const instance = new ethers.Contract(ERC721Address, erc721FactoryABI, signer);
        await instance.create(this.name, this.symbol, this.isBurnable, this.isMintable);
      }

      if (this.tokenType == ERC1155) {
        const instance = new ethers.Contract(ERC1155Address, erc1155FactoryABI, signer);
        await instance.create(this.name, this.uri, this.isMintable, this.erc1155IdList, this.erc1155NameList);
      }
    },
    async submit() {

      this.isLoading = true;
      this.hasError = false;
      this.hasSuccess = false;
      this.errorMessage = "";
      this.successMessage = "";

      if (this.isEmpty(this.name)) {
        this.hasError = true;
        this.errorMessage = "Token name field is empty";
      }
      else if (this.isEmpty(this.symbol) && this.tokenType != ERC1155) {
        this.hasError = true;
        this.errorMessage = "Token symbol field is empty";
      }
      else if (this.tokenType == ERC20 && this.tokenSupply <= 0) {
        this.hasError = true;
        this.errorMessage = "Token supply is zero or negative";
      }
      else {
        if (this.tokenType == ERC1155) {
          if (this.isEmpty(this.uri)) {
            this.hasError = true;
            this.errorMessage = "Token metadata url is empty";
          }

          if (this.isEmpty(this.nameList)) {
            this.hasError = true;
            this.errorMessage = "Token names list is empty";
          }

          if (this.isEmpty(this.idList)) {
            this.hasError = true;
            this.errorMessage = "Token id list is empty";
          }

          const names = this.nameList.split(",");
          this.erc1155NameList = names.map(element => {
            return element.trim();
          });

          const numbers = this.idList.split(",");
          this.erc1155IdList = numbers.map(Number);
        }

        try {
          this.toggleTabs(7)
          await this.createToken();
          this.hasSuccess = true;
          this.successMessage = this.tokenType == this.erc1155 ? `Successfully created your ERC1155 token` : `Successfully created your $${this.symbol} token`;
          this.toggleTabs(8)
          this.name = "";
          this.uri = "";
          this.symbol = "";
          this.nameList = "";
          this.idList = "";
          this.tokenSupply = 0;
        }
        catch (error) {
          this.toggleTabs(6)
          this.hasError = true;
          this.errorMessage = error.message;
        }
      }

      this.isLoading = false;
    },
    async addTokenToWallet(address, symbol){
      const tokenDecimals = 18;
      //const tokenImage = 'http://cdn-icons-png.flaticon.com/512/2395/2395608.png';

      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: address, // The address that the token is at.
              symbol: symbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              //image: tokenImage, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          this.congratulationIcon = true
          console.log('Thanks for your interest!');
        } else {
          console.log('Your loss!');
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeTokenType(event, type) {
      if(event != null){
        console.log('token-type:', event.target.value)
        this.tokenType = event.target.value;
      } else {

        this.tokenType = type ;
      }
    },
    changeNetworkType(type) {
      this.networkType = type
      let chainId = ''
      let rpcUrl = ''
      let chainName = ''
      let symbol = ''
      let scanUrl = ''
      if(type === 'POLY'){
        chainId = '0x89'
        rpcUrl = 'https://polygon-rpc.com'
        chainName = 'Polygon Mainnet'
        symbol = 'MATIC'
        scanUrl = 'https://polygonscan.com/'
      }
      if(type === 'ETH'){
        chainId = '0x1'
        rpcUrl = 'https://mainnet.infura.io/v3/'
        chainName = 'Ethereum'
        symbol = 'ETH'
        scanUrl = 'https://etherscan.io'
      }
      this.switchNetwork(chainId, chainName, symbol, rpcUrl, scanUrl)
    },
    async switchNetwork(chainId, chainName, symbol, rpcUrl, scanUrl) {
      // Check if MetaMask is installed
      // MetaMask injects the global API into window.ethereum

      console.log(rpcUrl)
      if (window.ethereum) {
        try {
          // check if the chain to connect to is installed
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }], // chainId must be in hexadecimal numbers
          });
        } catch (error) {
          // This error code indicates that the chain has not been added to MetaMask
          // if it is not, then install it into the user MetaMask
          if (error.code === 4902) {
            try {
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: chainId,
                    chainName: chainName,
                    nativeCurrency: {
                      name: symbol,
                      symbol: symbol, // 2-6 characters long
                      decimals: 18,
                    },
                    rpcUrls: [rpcUrl],
                    blockExplorerUrls: [scanUrl],
                  },
                ],
              });
            } catch (addError) {
              console.error(addError);
              console.log("I cant add this")
            }
          }
          console.error(error);
        }
      } else {
        // if no window.ethereum then MetaMask is not installed
        alert('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
      }
    },
    nextpage() {
      const currentPage = this.openTab;
      this.toggleNext(currentPage + 1);


    },
    backNavigation() {
      const currentPage = this.openTab;
      if (currentPage === 9) {
        this.toggleTabs(6)
      }
      if (currentPage > 0) {
        this.toggleTabs(currentPage - 1);
      }
    },
    clickBurnableToggle() {
      this.isBurnable = !this.isBurnable;
    },
    clickMintableToggle() {
      this.isMintable = !this.isMintable
    },
    checkCanVoteToggle(event) {
      this.isvoteable = event.target.checked;
    },
    onTextChange(event, field) {
      console.log('event.target', event.target.value);
      console.log('event.field', field);
      switch (field) {
        case 'name':
          if (event.target.value == '') {
            this.name = null;
          }
          this.circleTabs[1] = this.name != null ? true : false;
          this.lineTabs[1] = this.name != null ? true : false;
          break;
        case 'symbol':
          if (event.target.value == '') {
            this.symbol = null;
          }
          this.circleTabs[2] = this.symbol != null ? true : false;
          this.lineTabs[2] = this.symbol != null ? true : false;
          break;
        case 'supply':
          if (event.target.value == 0) {
            this.tokenSupply = null;
          }
          this.circleTabs[3] = this.tokenSupply != null ? true : false;
          this.lineTabs[3] = this.tokenSupply != null ? true : false;
          break;
      }

    }


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blur-box {
  background: linear-gradient(197.56deg, rgba(255, 255, 255, 1) 18.71%, rgba(255, 255, 255, 0.5) 98.35%);
  box-shadow: 0px 1px 20px -1px rgba(36, 86, 145, 0.16);
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 25px;
}


.blur-next {
  border-radius: 25px 0px 0px 25px;
}

.blur-back {
  border-radius: 0px 25px 25px 0px;
}


.hide {
  visibility: hidden;

}

.showinfo:hover+.hide {
  visibility: visible;

}

.min-size {
  min-width: 700px;
  min-height: 400px;
}

.min-win-size {
  min-width: 1200px;
  min-height: 900px;
}


.create-token input,
.create-token select {
  height: 40px;
  background: transparent;
  border: 1px #4FA9DB solid;
  color: #4FA9DB;
}

.create-token label {
  margin-top: 30px;
  font-weight: bolder;
  color: #4FA9DB;
  font-size: 20px;
}

.label {
  color: #4FA9DB !important;
  font-weight: bolder;
}

.create-token .padding-bottom-full {
  padding-bottom: 100px;
}

input:checked~.dot {
  transform: translateX(100%);
  background-color: #0e9362;
}

.create-token button {
  background: #4FA9DB;
  padding: 15px 45px;
  font-size: 18px;
  color: white;
  border-radius: 20px;
  margin-top: 50px;
  font-weight: bolder;
}

.create-token .centered {
  text-align: center;
}

img.loading {
  width: 50px;
}

h4.success {
  background: #38C5B2;
  color: #4FA9DB;
  padding: 20px 30px;
  border-radius: 15px;
}

h4.error {
  background: #E56668;
  color: #4FA9DB;
  padding: 20px 30px;
  border-radius: 15px;
}

h4.placeholder {
  padding: 20px 30px;
}

.fade {
  opacity: 0.8;
  background: #000;
  width: 200vh;
  height: 200vh;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden;
}
.box-bg{
  position: relative;
  background: linear-gradient(180deg, #FAFAFA 0%, #EFF6FF 100%), #FFFFFF;
  /* background-image: url('./assets/mobile-bg.svg'); */
}
.box-bg-image{
  pointer-events: none;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.4;
  background-image: url('./assets/mobile-bg.svg');
  background-repeat: no-repeat;
  background-clip: border-box;

}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 11;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #fd6c9e;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tab-content-height {
  min-height: 320px;
}
</style>
