<template>
   <!-- Mobile View -->
   <div class=" sm:hidden block">
    <div class="h-screen h-min-phone w-screen 
    box-bg flex flex-col justify-between">
      <div class="box-bg-image"> </div>
    <!--Main -->
    <div class="h-5/6 mx-4 flex flex-col  items-center min-h-min ">
      <!--Main Box  -->
      <div class="h-full mt-6 w-full flex flex-col items-center">
        <div class="h-32 w-32 my-16 ">
          <img src="./assets/osis_logo.svg" alt="">
        </div>
        <h1 class="text-2xl px-10 text-center font-bold text-gray-800">Welcome to the OSIS Launchpad</h1>
        <p class="mt-4 text-md px-10 text-center font-normal text-gray-800 mb-10 md:mb-20">
          Create your own blockchain assets, tokens, and NFTs in minutes - at a fraction of the cost. OSIS Launchpad
          (Patent Pending) is brought to you by OSIS.
        </p>

      </div>

    </div>

    
      <!--Buttton -->

      <div class="h-20 my-10 w-full  flex justify-center  ">

        <button @click="login" class="h-20 px-10 blur-box flex items-center justify-center">
          <h2 class="text-l text-blue-400 font-medium ">Start</h2>
          <img class="ml-4 " src="./assets/left-arrow.svg" alt="">
        </button>
      </div>


   

      <!--Footer -->

      <div class="h-20 bg-white justify-between flex items-center">

        <div class="flex ml-8 items-center">
          <img class="h-12 w-12 p-1 border-blue-300 border-4 rounded-full" src="./assets/logo_footer.svg" alt="">
          <h1 class="text-blue-300 text-xl ml-2 font-bold">OSIS Launchpad</h1>
        </div>

        <div class="mr-6 h-10 w-10">
          <img class="h-10 w-10" src="./assets/help-circle.svg" alt="">
        </div>
      </div>
  </div>
  </div>


  <!-- Large View-->

  <div class="sm:block hidden">
  <main class="h-screen w-screen bg-gradient-to-b from-blue-100 to-red-100">
        <section class="flex flex-col h-screen w-screen items-center " >
           
                    <section class="flex flex-col w-screen h-4/5 justify-between items-center lg:flex-row ">
                        <div></div>
                        <div class="blur-box flex flex-col items-center m-8 lg:max-w-screen-md md:max-w-screen-sm xl:max-w-screen-lg  " >
                            <div class="h-32 w-32 my-16 md:h-44 md:w-44 lg:h-60 lg:w-60">
                              <img src="./assets/osis_logo.svg" alt="">
                            </div>
                            <h1 class="text-3xl px-10 text-center font-bold text-gray-800">Welcome to the OSIS Launchpad</h1>
                            <p class="mt-4 text-lg px-10 text-center font-normal text-gray-800 mb-10 md:mb-20">
                              Create your own blockchain assets, tokens, and NFTs in minutes - at a fraction of the cost. OSIS Launchpad (Patent Pending) is brought to you by OSIS. 
                            </p>
                          </div>

                          <button @click="login" class="h-20 px-10 blur-box flex items-center justify-center lg:h-4/6 lg:px-4 lg:flex-col-reverse lg:blur-next"> 
                            <h2 class="text-l text-blue-400 font-medium ">Start</h2>
                            <img class="ml-4 lg:ml-0 lg:mb-2" src="./assets/left-arrow.svg" alt="">
                          </button>

                         
                    </section>
                    
                    <!-- <div class="h-20 w-2/5 blur-box flex items-center justify-center lg:invisible ">
                        <h2 class="text-xl text-blue-400 font-medium ">Start</h2>
                        <img class="ml-4" src="images/left-arrow.svg" alt="">
                    </div> -->
                    
           
            <div class="w-full h-1/5 flex flex-col">
                <div class="h-1/2"></div>
                <div class="h-1/2 bg-white flex justify-between items-center">
                    <div class="flex ml-8 items-center">
                        <img class="h-12 w-12 p-1 border-blue-300 border-4 rounded-full" src="./assets/logo_footer.svg" alt="">
                        <h1 class="text-blue-300 text-xl ml-2 font-bold">OSIS Launchpad</h1>
                    </div>
                    <h2 class="text-blue-300 md:invisible"></h2>
                    <div class="mr-6 h-10 w-10">
                        <img  class="h-10 w-10" src="./assets/help-circle.svg" alt="">
                    </div>
                </div>
            </div>
            
        </section>
    </main>
  </div>

</template>

<script>
const { ethers } = require("ethers");
// import WalletConnect from "@walletconnect/client";
// import QRCodeModal from "@walletconnect/qrcode-modal";

export default {
  name: 'HomeComponent',
  data() {
    return {
      hasEthProvider: false,
      walletAddress: "",
      nonce: "",
      hasError: false,
      loading: false,
      completed: false,
      success: false,
      errorMessage: ""
    }
  },
  methods: {
    async login() {
      const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      if (provider != null)
      {
        this.hasEthProvider = true
      }

      // this.connectButtonClick()

      // Prompt user for account connections
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      if (address != null){
        this.$router.push('/token/create');
      }
    },

    // connectButtonClick() {
    //   // Create a connector
    //   const connector = new WalletConnect({
    //     bridge: "https://bridge.walletconnect.org", // Required
    //     qrcodeModal: QRCodeModal,
    //   });
    //
    //   // // Check if connection is already established
    //   // if (!connector.connected) {
    //   //   // create new session
    //   //   connector.createSession();
    //   // }
    //   //
    //   // // Subscribe to connection events
    //   // connector.on("connect", (error, payload) => {
    //   //   if (error) {
    //   //     throw error;
    //   //   }
    //   //   // Get provided accounts and chainId
    //   //   const { accounts, chainId } = payload.params[0];
    //   // });
    //   //
    //   // connector.on("session_update", (error, payload) => {
    //   //   if (error) {
    //   //     throw error;
    //   //   }
    //   //   // Get updated accounts and chainId
    //   //   const { accounts, chainId } = payload.params[0];
    //   // });
    //   //
    //   // connector.on("disconnect", (error, payload) => {
    //   //   if (error) {
    //   //     throw error;
    //   //   }
    //   // });
    //
    // }

  }
}
</script>

<style scoped>

.blur-box{
    background: linear-gradient(197.56deg, rgba(255, 255, 255, 0.9) 18.71%, rgba(255, 255, 255, 0) 98.35%);
    box-shadow: 0px 1px 20px -1px rgba(36, 86, 145, 0.16);
    backdrop-filter: blur(25px);
    border-radius: 25px;    
   }

   @media (min-width: 1024px) {
    .lg\:blur-next{
      border-radius: 25px 0px 0px 25px; 
    }
}
   .blur-next{
    background: linear-gradient(197.56deg, rgba(255, 255, 255, 0.9) 18.71%, rgba(255, 255, 255, 0) 98.35%);
    box-shadow: 0px 1px 20px -1px rgba(36, 86, 145, 0.16);
    backdrop-filter: blur(25px);
    border-radius: 25px 0px 0px 25px;    
   }

.box-bg{
  position: relative;
  background: linear-gradient(180deg, #FAFAFA 0%, #EFF6FF 100%), #FFFFFF;
  /* background-image: url('./assets/mobile-bg.svg'); */
}
.box-bg-image{
  pointer-events: none;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.6;
  background-image: url('./assets/mobile-bg.svg');
  background-repeat: no-repeat;
  background-clip: border-box;

}

/* .home{
  text-align: center;
  margin-top: 160px;
}
.home h1{
  color:#212144;
  font-size: 32px;
  margin: 0px;
}
.home p{
  color:#212144;
  font-size: 14px;
  margin: 0px;
}
.home button{ 
  background: #212144;
  padding: 15px 35px;
  font-size: 18px;
  color: white;
  border-radius: 20px;
  margin-top: 50px;
} */
</style>
