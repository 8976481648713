const ERC20 = "ERC20";
const ERC721 = "ERC721";
const ERC1155 = "ERC1155";
const ERC20GoerliAddress = '0xf51e586E1B354B0d1Df5444959a74C6CacBd2D4b';
const ERC20EthMainnetAddress = '0xFe02DB956d2a8954d4f29649E3b73165F52E4440';
const ERC20PolygonAddress = '0x3b56492Ef4Da5996Eb5063298Dd8d3BaaCCf0578'
const ERC20MumbaiAddress = '0xeb37E2e0214E7832E01cd68548e70CAb01Ce1feb';
const ERC721Address = '0x31A66CA674200fBa0Db1284760a623619eD1CC82';
const ERC1155Address = '0xf9f550e7310cd1E08158aDbdEB7a6a5E4c659eEd';
    
export {
    ERC20,
    ERC721,
    ERC1155,
    ERC20GoerliAddress,
    ERC20EthMainnetAddress,
    ERC20PolygonAddress,
    ERC20MumbaiAddress,
    ERC721Address,
    ERC1155Address
}